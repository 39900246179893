import { Fade } from 'components/fade'
import { NextIcon } from 'components/icons/next'
import { TwitterLogo } from 'components/icons/twitter'
import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { Article } from '../utility/components'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'

interface Source {
  community_id?: string,
  identifier: string,
  name: string,
  type: string,
}

interface TwitterIdentifiers {
  identifiers: any,
}

type AddSourceProps = TwitterIdentifiers & GenericRedux.AllComponentProps<Source>

interface AddSourceState {
  existingSources: any[],
  identifier: any,
  isLoading: boolean,
  name: string,
  accountFeeds: any[],
  accountFeedsFound: boolean,
  selectedAccount: any,
  sourceAccounts: any,
  sourceAccountsFound: boolean,
  sourceHandleInput: string,
}

class ContentPoolAddTwitterSourceComponent extends RasaReactComponent<AddSourceProps, AddSourceState> {
  public static contextType = RasaContext;
  constructor(props: AddSourceProps) {
    super(props, 'communitySource', {
      existingSources: [],
      isLoading: false,
      identifier: '',
      name: '',
      accountFeeds: [],
      accountFeedsFound: true,
      selectedAccount: {},
      sourceAccounts: [],
      sourceAccountsFound: true,
      sourceHandleInput: '',
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.loadRecord(activeCommunity.communityId, null)
      new Dataset()
        .loadCommunityDataset('communitySources', activeCommunity.communityId)
        .then((response) => {
          this.setState({existingSources: response[0] || []})
        })
    })
  }

  public render = () => {
    return <div
              className={`${this.context.store.getState().config.isMobile ? 'add-twitter-wrapper-mobile' : 'add-twitter-wrapper'}`}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Add New Twitter Source</div>
      <p className="section-detail-text">Add a Twitter account and we will pull in tweeted articles.</p>
      {isEmpty(this.state.selectedAccount) &&
        <div className="twitter sources">
          <div className="image">
            <TwitterLogo/>
          </div>
          <div className="words">
            <h2>Twitter</h2>
            <p>Add a Twitter handle</p>
            <div className="input-area">
              <span className="twitter-prefix"><strong>@</strong></span>
              <form onSubmit={preventDefaultThen(() => this.searchUsers(this.state))}>
                <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                  value={this.state.sourceHandleInput}
                  onChange={(e: any) => this.setState({ sourceHandleInput: e.target.value })}/>
                <Button disabled={this.state.sourceHandleInput.trim() === '' || this.state.isLoading}>Next</Button>
              </form>
            </div>
            {!this.state.sourceAccountsFound && <span className="warning">We could not locate this handle</span>}
          </div>
        </div>
      }
      {!isEmpty(this.state.selectedAccount) &&
        <div className="twitter confirm sources">
          <div className="image">
            <TwitterLogo/>
          </div>
          <div className="words">
            <h2>Twitter</h2>
            <p>
              <strong className="feed-result-text">We found the account, {this.state.selectedAccount.name}.</strong>
            </p>
            <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
            {!isValidSourceName(this.state.existingSources, this.state.name) &&
            <div className="invalid-source-name-container">You already have a source with this name</div>}
            <div className="input-area">
              <form onSubmit={preventDefault()}>
                <input autoFocus className="field-textbox"
                  value={this.state.name}
                  onChange={(e: any) => this.setState({name: e.target.value})}
                />
                <Button onClick={() => this.addSource({
                  community_id: this.context.user.activeCommunity.communityId,
                  identifier: this.state.selectedAccount.screen_name,
                  name: this.state.name,
                  type: SourceTypes.twitter,
                })}
                  disabled={this.state.isLoading ||
                  !isValidSourceName(this.state.existingSources, this.state.name)}>Confirm</Button>
                <Button className="change" onClick={() => this.changeSelection()}>Cancel</Button>
              </form>
            </div>
          </div>
        </div>
      }
      <div className="twitter-search-results">
        {this.state.sourceAccounts.map((user: any) =>
          <button key={user.id} onClick={(e: any) => this.getFeed(user) }>
            <Fade>
              <div className="result">
                <div className="profile-image"><img src={user.profile_image_url_https} /></div>
                <div className="info">
                  <div className="user-name">{user.name}</div>
                  <div className="screen-name">@{user.screen_name}</div>
                  <div className="description">{user.description}</div>
                </div>
                <div className="next">
                  <NextIcon/>
                </div>
              </div>
            </Fade>
          </button>)}
      </div>
      {this.state.isLoading && <Loading.Loading size={Loading.SIZES.LARGE}/>}
      {this.state.accountFeeds.length > 0 &&
          <div className={`${this.context.store.getState().config.isMobile ? 'recent-articles-mobile' : 'recent-articles'}`}>
              <h4 className="recent-articles-title">Recent Articles</h4>
          {this.state.accountFeeds.slice(0, 5).map((feed: any, i: number) =>
          <Article key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
          {!this.state.accountFeedsFound && <span className="warning">We could not find any article.</span>}
        </div>
      }
    </div>
  }

  private searchUsers(state) {
    this.setState({isLoading: true}, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/twitter/users/search?q=${state.sourceHandleInput}`,
        HttpMethod.GET,
        {},
      )
      .then((sourceAccounts) => {
        const userFound = sourceAccounts.length === 1 ? sourceAccounts[0] :
          sourceAccounts.filter((x) => x.screen_name.toLowerCase() === state.sourceHandleInput.toLowerCase())[0]
        if (userFound) {
          this.getFeed(userFound)
        } else {
          this.setState({
            sourceAccounts,
            sourceAccountsFound: sourceAccounts.length > 0,
            isLoading: false,
          })
        }
      }),
    )
  }
  private getFeed(user) {
    this.setState({isLoading: true, name: user.name, selectedAccount: user, sourceAccounts: []}, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/twitter/users/tweets?q=${user.screen_name}`,
        HttpMethod.GET,
        {},
      )
      .then((accountFeeds) => {
        this.setState({
          accountFeeds,
          accountFeedsFound: accountFeeds.length > 0,
          isLoading: false,
        })
      }),
    )
  }
  private changeSelection() {
    this.setState({
      accountFeeds: [],
      accountFeedsFound: true,
      selectedAccount: {},
      sourceAccounts: [],
      sourceAccountsFound: true,
    })
  }

  private addSource = (source: Source) => {
    this.setState({communityId: this.context.user.activeCommunity.communityId}, () => {
      this.props.propertiesChanged({
        ...source,
        community_id: this.context.user.activeCommunity.communityId,
      })
      this.saveRecord(null).then(() => {
        postAddSource(this.context, 'twitter', source.identifier, this.props.push)
      })
    })
  }
}

export const ContentPoolAddTwitterSource = GenericRedux.createConnect(ContentPoolAddTwitterSourceComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
