import * as Flash from 'components/flash'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import { RasaContext } from '../../../context'
import { SharedKeys, SharedStore } from '../../../shared/data-layer/sharedStore'
import { Roles } from '../../../shared/constants'
import { Loading } from '../../loading'
import { GridCellProps, GridRowClickEvent } from '@progress/kendo-react-grid'
import classnames from 'classnames'
import { RasaDataGrid } from '../../../generic/rasaDataGrid'
import { DashboardMenuOption } from '../../dashboard-menu/constants'
import { TooltipCellHeader } from '../kendocells'
import * as GenericRedux from '../../../generic/genericRedux'
import * as Modals from '../../../shared/modals'
import { Article } from '../utility/components'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { AjaxWrapper, HttpMethod } from '../../../generic/ajaxWrapper'
import * as Errors from 'shared_server_client/types/error'
import { getCurrentPlanMaxSources } from '../../../shared_server_client/types/billing_plan'
import { AddIconCell } from '../kendocells/addIconCell'
import { RasaBrowserComponent } from '../../../generic/rasaBrowserComponent'
import * as Columns from '../../grid/columns'
import {SuggestedArticle, SuggestedSource} from '../types'

interface SuggestedSourcesProps {
  id: number,
  title: string,
  url: string,
  modals: any,
  closeModal: any,
  openModal: any,
  push: any,
  propertiesChanged: any
}

interface SuggestedSourcesState {
  isSuperUser: boolean
  loading: boolean
  maxAllowedSources: number
  addSourcesCount: number
  selectedItemsCount: number
  isLimitReached: boolean
  communityId: string
  sources: SuggestedSource[]
  recentArticles: any[],
  topics: string,
  columns: Columns.SizableColumns,
  panelWidth: number,
  currentRole: string,
}

export class SuggestedSourcesComponentClass extends RasaBrowserComponent<SuggestedSourcesProps, SuggestedSourcesState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: SuggestedSourcesProps) {
    super(props, {
      isSuperUser: false,
      loading: false,
      maxAllowedSources: 0,
      selectedItemsCount: 0,
      addSourcesCount: 0,
      isLimitReached: false,
      communityId: null,
      sources: [],
      recentArticles: [],
      topics: '',
      columns: null,
      panelWidth: 0,
      currentRole: '',
    })
  }


  public componentDidMount() {
    this.loadStateFromUrl().then(() => {
      if (!this.state.topics) {
        this.props.push(DashboardMenuOption.suggestContent)
      }
    })
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity)
    ]).then(([activeCommunity]) => {
      this.setState({
        maxAllowedSources: getCurrentPlanMaxSources(activeCommunity.billingInfo.currentPlan),
        addSourcesCount: activeCommunity.billingInfo.usageStats.sourcesCount,
        communityId: activeCommunity.communityId,
        isSuperUser: activeCommunity.role === Roles.super_admin,
        isLimitReached: this.checkSourceLimit(),
        columns: new Columns.SizableColumns(this.getColumns()),
        panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
        currentRole: activeCommunity.role,
      })
    })
  }

  public render() {
    return <div
      className={classnames('add-suggested-wrapper', this.context.store.getState().config.isMobile ? 'add-suggested-wrapper-mobile' : '')}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Suggested Sources</div>
      <p className="section-detail-text">Use our AI to find new sources.</p>
      <div className="text-right my-3">
        <Button disabled={this.state.selectedItemsCount === 0 || this.state.isLimitReached} onClick={() => this.addSource()}>Add {this.state.selectedItemsCount} Sources</Button>
      </div>
      <div className="rasa-data-grid">
        { this.state.loading ? <Loading size="64"/> : <RasaDataGrid<SuggestedArticle>
          entityName="suggestedSources"
          datasetName="suggestedSources"
          datasetParams={[{
            param: 'topics',
            value: this.state.topics,
          }]}
          pageable={false}
          editDefaultState={true}
          gridTransform={this.transformData}
          onDataReceived={this.dataReceived}
          onRowClick={(e: GridRowClickEvent) => this.loadRecentArticleAndOpenModel(e.dataItem)}
          data={[]}
        >
          {this.state.columns && this.state.columns.buildColumns(this.state.panelWidth, this.state.currentRole)}
        </RasaDataGrid>
        }
      </div>
      <div className="modal-wrapper">
        <SuggestedArticleModal data={this.props.modals} closeModal={this.props.closeModal} title="Suggested Articles"/>
      </div>
    </div>
  }

  private loadRecentArticleAndOpenModel = (selected: any) => {
    this.setState({
      loading: true,
      recentArticles: []
    })
    const url = AjaxWrapper.getServerUrl() + `/dataset/${this.state.communityId}/suggestedArticles`
    const payload = {
      id: selected.id
    }

    void AjaxWrapper.ajax(url, HttpMethod.GET, payload)
      .then((response: any) => {
        if (response[0].length) {
          this.setState({
            loading: false,
            recentArticles: response[0],
          }, () => this.props.openModal(SuggestedArticleModal.key, this.state.recentArticles))
        }
      })
  }

  private transformData = (item) => {
    const stateSources = this.state.sources.filter((x: SuggestedSource) => x.source_url === item.source_url)
    return stateSources.length ? stateSources[0] : item
  }

  private dataReceived = (data) => {
    if (data.data) {
      this.setState({
        sources: data.data,
      })
    }
    if (data.message && isEmpty(data.data)) {
      this.context.store.dispatch(Flash.showFlashError(Errors.getErrorMessage(data.message)))
    }
  }

  private ToggleTooltip = (props: any) => {
    const tooltipText = 'Click here to clear selection'
    return <TooltipCellHeader className='red' {...props} onClick={this.clearSelection} tooltip={tooltipText}/>
  }

  private AddIconCell = (props: GridCellProps) => {
    return <AddIconCell {...props} onToggle={this.toggleSelection}/>
  }

  private clearSelection = () => {
    this.setState({
      sources: this.state.sources.map((a) => {
        return {
          ...a,
          checked: false,
        }
      }),
      selectedItemsCount: 0,
      isLimitReached: this.checkSourceLimit(0),
    })
  }

  private toggleSelection = (source: SuggestedSource, checked: boolean) => {
    this.setState({
      sources: this.state.sources.map((a) => {
        if (a.source_url === source.source_url) {
          return {
            ...a,
            checked,
          }
        }
        return a
      }),
    }, () => {
      const addedSourceLength = this.filterSelectedSources().length
      this.setState({
        selectedItemsCount: addedSourceLength,
        isLimitReached: this.checkSourceLimit(addedSourceLength)
      })
    })
  }

  private filterSelectedSources = () => {
    return this.state.sources.filter((i) => i.checked === true);
  }
  private checkSourceLimit = (selectedItemsCount = 0): boolean => {
    return this.state.addSourcesCount + selectedItemsCount > this.state.maxAllowedSources
  }

  public addSource() {
    if (this.state.selectedItemsCount === 0 || this.state.isLimitReached) {
      return
    }
    this.setState({
      loading: true,
    })
    return this.context.entityMetadata.getEntityObject('communitySource').then((entity) => {
      entity.setRecordId(this.state.communityId, null);
      entity.data.sources = this.filterSelectedSources().map((source) => {
        return {
          id: source.source_id,
          name: source.name
        }
      })
      return entity.save()
        .then(() => {
          this.props.push(`${DashboardMenuOption.sources}`)
        })
        .catch(() => {
        })
    })
  }

  private getColumns = () => [
    {
      field: 'source_type',
      title: 'Source',
      width: 120,
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'source_url',
      title: 'Source URL',
    },
    {
      cell: this.AddIconCell,
      field: 'is_selected',
      headerCell: this.ToggleTooltip,
      title: 'Clear Selection',
      width: 120,
    }]
}

class SuggestedArticleModal extends Modals.ModalComponent {
  public static key: string = 'suggestedArticleModal'
  public static contextType = RasaContext

  constructor(props: Modals.ModalComponentProps) {
    super(props, SuggestedArticleModal.key)
  }

  protected renderChildren(data: []) {
    return data.length > 0 ? <div
      className={`${this.context.store.getState().config.isMobile ? 'recent-articles-mobile' : 'recent-articles'}`}>
      {data.slice(0, 5).map((feed, i) =>
        <Article key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
    </div> : <>No data found</>
  }
}

export const SuggestedSources = GenericRedux.registerNewComponentWithModals<any>(
  SuggestedSourcesComponentClass, 'suggested-sources', [SuggestedArticleModal.key])
