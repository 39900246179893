
export interface ChangeEvent {
  field?: string,
  value: any,
}

export type ChangeFunction = (event: ChangeEvent) => void

export type ArticleChangeFunction = (articleId: number, event: ChangeEvent) => void

export interface Article {
  boost_level: number,
  created: string,
  description: string,
  edit: any,
  end_date?: string,
  field_name: any,
  hosted_image_url: any,
  id: any,
  is_active: number,
  scheduled_article_id?: number,
  site_name: any,
  source: any,
  source_name: any,
  start_date?: string,
  subscription_type: string,
  title: string,
  url: string,
  is_reserved: boolean,
  image_width: string,
  image_height: string,
  section_name: string,
  last_issue_id: number,
  last_updated_community_person_id?: number,
  post_id?: number,
  community_partner_code?: string,
  blacklist_match?: string,
}
export interface SuggestedArticle extends Article {
  checked?: boolean,
  post_id?: number,
}
export interface DisplayArticle extends Article {
  duplicates: Article[],
  post_subscription_detail_id?: string,
  options?: any,
}

export interface FeedArticle {
  author: string
  description: string
  feedUrl: string
  image: string
  pubDate: string
  publisher: string
  title: string
  url: string
}

export const EmptyArticle: Article = {
  boost_level: null,
  created: '',
  description: '',
  edit: null,
  field_name: null,
  hosted_image_url: null,
  id: null,
  is_active: null,
  site_name: null,
  source: null,
  source_name: null,
  subscription_type: '',
  title: '',
  url: '',
  is_reserved: null,
  image_width: null,
  image_height: null,
  section_name: '',
  last_issue_id: null,
}
export const EmptyDisplayArticle: DisplayArticle = {
  ...EmptyArticle,
  duplicates: [],
}

export interface SourceSection {
  category_type: string,
  category_type_id: number,
  community_id: number
  created: string
  id: number,
  is_active: number,
  last_week_quality: number,
  last_week_total: number,
  name: string,
  updated: string,
}

export interface ArticleCount {
  Count: number,
  Type: string,
}

export enum ArticleSections {
  active = 'article_active',
  inactive = 'article_inactive',
  scheduled = 'article_scheduled',
}

export interface Source {
  id: number,
  source_id: string,
  source_type: string,
  name: string,
  source_url: string,
  last_week_total: number,
}

export interface SuggestedSource extends Source {
  checked?: boolean,
}