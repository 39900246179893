import { Grid } from '@progress/kendo-react-grid'
import React from 'react'
import { RasaContext } from '../../../context'
import { SharedKeys, SharedStore } from '../../../shared/data-layer/sharedStore'
import { HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import { Loading } from '../../loading'
import classnames from 'classnames'
import { DashboardMenuOption } from '../../dashboard-menu/constants'
import * as GenericRedux from '../../../generic/genericRedux'
import { RasaBrowserComponent } from '../../../generic/rasaBrowserComponent'
import * as Columns from '../../grid/columns'

interface SuggestedContentProps {
  id: number,
  title: string,
  url: string,
  push: any,
  propertiesChanged: any
}

interface SuggestedContentState {
  columns: Columns.SizableColumns,
  content: any[],
  loading: boolean,
  panelWidth: number,
  summary: string,
  topics: string,
}

export class SuggestedContentComponentClass extends RasaBrowserComponent<SuggestedContentProps, SuggestedContentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: SuggestedContentProps) {
    super(props, {
      columns: null,
      content: [],
      loading: true,
      panelWidth: 0,
      summary: '',
      topics: '',
    })
  }


  public componentDidMount() {
    this.loadStateFromUrl().then(() => {
      if (!this.state.topics) {
        this.props.push(DashboardMenuOption.suggestContent)
      }
    })
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity)
    ]).then(([activeCommunity]) => {
      this.setState({
        columns: new Columns.SizableColumns(this.getColumns()),
        panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
      })
      this.fetchRecommendations(activeCommunity.communityId)
    })
  }

  public render() {
    return <div
      className={classnames('add-suggested-wrapper', this.context.store.getState().config.isMobile ? 'add-suggested-wrapper-mobile' : '')}>
      <h2 className="sources-heading">Content</h2>
      <div className="section-header-text">Suggested Sources</div>
      <p className="section-detail-text">Use our AI to find new sources.</p>
      <div className="rasa-data-grid">
        { this.state.loading ? <Loading size="64"/> :
          <Grid data={this.state.content}>
          {this.state.columns && this.state.columns.buildColumns(this.state.panelWidth, '')}
        </Grid>
        }
      </div>
    </div>
  }

  private fetchRecommendations = (communityId) => {
    return new Dataset()
      .loadCommunityDataset('suggestedContent', communityId, [
        {
          param: 'topics',
          value: this.state.topics,
        },
        {
          param: 'summary',
          value: this.state.summary
        }
      ], null, null, null, null, HttpMethod.POST)
      .then((data) => this.dataReceived(data))
    }

  private dataReceived = (data) => {
    this.setState({
      content: data.records,
      loading: false,
    })
  }

  private getColumns = () => [
    {
      field: 'weight',
      title: 'Weight',
      width: 120,
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'title',
      title: 'Title',
    },
    {
      field: 'url',
      title: 'URL',
    }
  ]
}

export const SuggestedContentComponent = GenericRedux.registerNewComponent<any>(
  SuggestedContentComponentClass, 'suggested-content', {})
