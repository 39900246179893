import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as Flash from 'components/flash'
import { Loading } from 'components/loading'
import { HttpMethod } from 'generic/ajaxWrapper'
import { RasaBrowserComponent } from 'generic/rasaBrowserComponent'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import * as GenericRedux from 'generic/genericRedux'
import { isEmpty } from 'lodash'
import React from 'react'
import { ArticleLink, DateTimeCell, ImageCell, TitleCell, TooltipCellHeader } from '../kendocells'
import { EditCellProps } from 'generic/editCell'
import { GridCellProps } from '@progress/kendo-react-grid'
import * as Columns from '../../grid/columns'
import { RasaContext } from 'context'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { AddIconCell } from '../kendocells/addIconCell'
import { HeaderComponent } from 'components/header/component'
import { Button } from 'reactstrap'
import { SuggestedArticle } from '../types'
import { NORMAL_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'
import * as Errors from 'shared_server_client/types/error'
import { isInCutoffWindow } from 'shared/data-layer/issues'

interface SuggestedArticlesComponentState {
  activeCommunity: any,
  topics: string,
  summary: string,
  columns: Columns.SizableColumns,
  isLoading: boolean,
  panelWidth: number,
  currentRole: string,
  articles: SuggestedArticle[],
}
export class SuggestArticlesComponentClass extends RasaBrowserComponent<any, SuggestedArticlesComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props, {
      activeCommunity: {},
      topics: '',
      summary: '',
      columns: null,
      isLoading: false,
      panelWidth: 0,
      currentRole: '',
      articles: [],
    })
  }
  public componentDidMount(): void {
    this.sharedStore = SharedStore.instance(this.context)
    this.initialize()
  }
  public render() {
    return <div className="suggested-artcles">
      <div className="header-container">
        <HeaderComponent
          title={'Suggested Content'}
          subTitle={'Suggested Articles'}
          description={[]}
        />
        <div className="actions-container">
          <Button disabled={this.getSelectedArticlesCount() === 0} onClick={this.moveContentToUpcoming} className="move-to-upcoming-button">
            Add {this.getSelectedArticlesCount()} to Upcoming
          </Button>
        </div>
      </div>
      {this.state.isLoading ? <Loading size="64" /> :
      <RasaDataGrid<SuggestedArticle>
          // Rasa Properties for entity, datasetName etc
          entityName="communityArticle"
          datasetName="suggestedArticles"
          datasetMethod={HttpMethod.POST}
          datasetParams={[{
              param: 'topics',
              value: this.state.topics,
            },
            {
              param: 'summary',
              value: this.state.summary
            }
          ]}
          editDefaultState={true}
          onDataReceived={this.dataReceived}
          gridTransform={this.transformData}
          // events
          // onRowClick={(p: GridRowClickEvent) =>  this.editPost(p.dataItem)}
          pageable={false}
          data={[]}>
        {this.state.columns && this.state.columns.buildColumns(this.state.panelWidth, this.state.currentRole)}
      </RasaDataGrid>}
    </div>
  }

  private moveContentToUpcoming = () => {
    this.setState({
      isLoading: true,
    })
    return this.context.entityMetadata.getEntityObject('communityArticle').then((entity) => {
      entity.setRecordId(this.state.activeCommunity.communityId, null);
      entity.data.post_ids = this.state.articles.filter((a) => a.checked).map((a) => a.post_id).join(',')
      if (isInCutoffWindow(this.state.activeCommunity.nextIssue)) {
        entity.data.boost_level = NORMAL_BOOSTED_BOOST_LEVEL
        entity.data.created = this.state.activeCommunity.nextIssue.cutoff
      }
      return entity.save()
        .then(() => {
          this.setState({
            isLoading: false,
          })
          this.props.push(`${DashboardMenuOption.content}`)
        })
        .catch(() => {
        })
    })
  }

  private dataReceived = (data) => {
    if (data.data) {
      this.setState({
        articles: data.data,
      })
    }
    if (data.message && isEmpty(data.data)) {
      this.context.store.dispatch(Flash.showFlashError(Errors.getErrorMessage(data.message)))
    }
  }

  private initialize = () => {
    this.loadStateFromUrl().then(() => {
      if (!this.state.topics) {
        this.props.push(`${DashboardMenuOption.content}/suggest`)
      }
    })
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ])
    .then(([activeCommunity]) => {
      this.setState({
        activeCommunity,
        columns: new Columns.SizableColumns(this.getColumns()),
        panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
        currentRole: activeCommunity.role,
      })
    })
  }

  private getSelectedArticlesCount = () => {
    const filteredArticles = this.state.articles.filter((a) => a.checked)
    return filteredArticles.length
  }

  protected transformData = (a) => {
    const stateArticle = this.state.articles.filter((x) => x.url === a.url)
    const article = stateArticle.length ? stateArticle[0] : a
		return {
      ...article,
      checked: article.checked || false,
    }
  }

  private toggleSelection = (article: SuggestedArticle, checked: boolean) => {
    this.setState({
      articles: this.state.articles.map((a) => {
        if (a.url === article.url) {
          return {
            ...a,
            checked,
          }
        }
        return a
      }),
    })
  }

  private AddIconCell = (props: GridCellProps) => {
    return <AddIconCell {...props} onToggle={this.toggleSelection}/>
  }
  private ImageCell = (props: EditCellProps) => {
    return <ImageCell {...props} />
  }

  private ToggleTooltip = (props: any) => {
    const tooltipText = 'Click here to clear selection'
    return <TooltipCellHeader className='red' {...props} onClick={this.clearSelection} tooltip={tooltipText}/>
  }

  private clearSelection = () => {
    this.setState({
      articles: this.state.articles.map((a) => {
        return {
          ...a,
          checked: false,
        }
      }),
    })
  }

  private TitleCell = (props: any) => {
    return <TitleCell {...props} />
  }

  private getColumns = (): Columns.SizableColumn[] => {
    return [
      {
        cell: this.ImageCell,
        field: '',
        minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
        sortable: false,
        title: ' ',
        width: 130,
      },
      {
        cell: this.TitleCell,
        field: 'title',
        minWidth: 300,
        title: 'Article',
      },
      {
        cell: ArticleLink,
        sortable: false,
        title: '',
        width: 35,
      },
      {
        field: 'site_name',
        title: 'Source',
        width: 150,
      },
      {
        cell: DateTimeCell,
        field: 'created',
        minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
        title: 'Published',
        width: 150,
      },
      {
        cell: this.AddIconCell,
        field: 'checked',
        headerCell: this.ToggleTooltip,
        title: 'Clear Selection',
        width: 120,
      }
    ]
  }
}

export const SuggestArticlesComponent = GenericRedux.registerNewDatasetContainerComponent(
  SuggestArticlesComponentClass, 'suggestedArticles')