import * as GenericRedux from 'generic/genericRedux'
import * as ReduxObservable from 'redux-observable'
import {errorConstant, successConstant} from 'request'
import {of} from 'rxjs'
import {ajax} from 'rxjs/ajax'
import {catchError, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators'
import serializeError from 'serialize-error'
import * as Constants from './constants'

const findTwitterEpic: ReduxObservable.Epic = (action$, state$) =>
  action$.ofType(GenericRedux.genericActionType(Constants.REDUX_STORE_HOME))
    .pipe(
      distinctUntilChanged(),
      filter((action) => action.data),
      switchMap((action) => ajax({
          headers: {},
          method: 'get',
          responseType: 'json',
          url: state$.value.config.api + `/twitter/users/search?q=@${action.data.identifier}`,
        }).pipe(
          map(({response}) => ({
            payload: {response},
            type: successConstant(Constants.SEARCH_TWITTER_HANDLE),
          })),
          catchError((e: Error) => {
            // eslint-disable-next-line no-console
            console.error(e)
            return of({
              error: serializeError(e),
              type: errorConstant(Constants.SEARCH_TWITTER_HANDLE),
            })
          }),
        ),
      ),
    )

export const epic = ReduxObservable.combineEpics(findTwitterEpic)
