import {
  ChatResponse,
  ChatCallback,
} from 'shared_server_client/types/generative_ai'
import { AjaxStreamCallback, AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'

export const streamChatResponse = (
  communityId: string,
  payload: any,
  url: string,
  callback: ChatCallback) => {
  url = `${AjaxWrapper.getServerUrl()}/ai/${communityId}/${url}`
  const responseParser: AjaxStreamCallback = (success: boolean, chunk: string) => {
    if ( !chunk ) {
      callback(null)
    } else if ( !success ) {
      callback({
        done: true,
        error: chunk,
        message: chunk,
      })
    } else {
      const jsonString: string = '[' + chunk.replace(/}{/g, '},{') + ']'
      try {
        JSON.parse(jsonString).forEach((r: ChatResponse) => {
          callback(r)
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR parsing ' + jsonString)
      }
    }
  }
  AjaxWrapper.stream(url, HttpMethod.POST, { ...payload, stream: true}, responseParser)
}

export const streamArticleResponse = (
  communityId: string,
  payload: any,
  callback: ChatCallback,
  isMoreArticle: boolean = false) => {
  const url: string =
    `${AjaxWrapper.getServerUrl()}/ai/${communityId}/suggest-${isMoreArticle ? 'more-': ''}articles`
  const responseParser: AjaxStreamCallback = (success: boolean, chunk: string) => {
    if ( !chunk ) {
      callback(null)
    } else if ( !success ) {
      callback({
        done: true,
        error: chunk,
        message: chunk,
      })
    } else {
      const jsonString: string = '[' + chunk.replace(/}{/g, '},{') + ']'
      try {
        JSON.parse(jsonString).forEach((r: ChatResponse) => {
          callback(r)
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR parsing ' + jsonString)
      }
    }
  }
  AjaxWrapper.stream(url, HttpMethod.POST, { ...payload, stream: true}, responseParser)
}
