import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { Article } from '../utility/components'
import { postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import { UpgradeButton } from '../../upgrade/button'
import { UPGRADE_BUTTON_COLOR } from '../../upgrade/constants'
import * as GA from '../../../google-analytics'

interface AddFeedState {
  error?: string,
  articles: any,
  found: boolean,
  isLoading: boolean,
  name: string,
  selectedCommunity: string,
  topic: string,
  plan?: BillingPlan.BillingPlan
  topicSourceList?: any
  accountTopicSourcesCount: number
}

class ContentPoolAddTopicSourceComponent extends RasaReactComponent<any, AddFeedState> {
  public static contextType = RasaContext;
  constructor(props: any) {
    super(props, 'communitySource', {
      accountTopicSourcesCount: 0,
      articles: [],
      found: true,
      isLoading: false,
      name: '',
      selectedCommunity: '',
      topic: '',
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.selectCommunity(activeCommunity.communityId)
      this.setState({
        accountTopicSourcesCount: activeCommunity.billingInfo.usageStats.topicSourcesCount,
        selectedCommunity: activeCommunity.communityId,
        plan: activeCommunity.billingInfo.currentPlan,
        topicSourceList: this.filterTopicSourceTypeId(activeCommunity.communityInfo.data.sources)
      })
    })
  }

  public render = () => {
    return (
      !this.state.isLoading && this.state.plan ? <div className={`${this.context.store.getState().config.isMobile ? 'add-topic-wrapper-mobile' : 'add-topic-wrapper'}`}>
        <div className="standard-header-source">
          <div className="titles-and-description-container">
              <h2 className="sources-heading">Sources</h2>
              <div className="section-header-text">Add a New {Constants.SOURCE_NAME} Feed</div>
              <p className="section-detail-text">Specify some topics, and let t-rex find some content.</p>
              {isEmpty(this.state.articles) ? !this.maxTopicLimit().maxLimitReached &&
                  <div className="topic sources">
                      <div className="image">
                          <i className="fa fa-list fa-2" aria-hidden="true"></i>
                      </div>
                      <div className="words">
                          <h2>{Constants.SOURCE_NAME}</h2>
                          <p>Specify some topics for your newsletter</p>
                          <div className="input-area">
                              <form onSubmit={preventDefaultThen(() => this.searchSource())}>
                                  <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                                         value={this.state.topic}
                                         onChange={(e: any) => this.setState({ topic: e.target.value })}/>
                                  <Button disabled={this.state.isLoading || !this.isValidTopic()}>Next</Button>
                              </form>
                          </div>
                        {  !this.state.found &&
                            <span className="warning">
            {this.state.error || 'We could not locate any articles for this topic'}
          </span>
                        }
                      </div>
                  </div>
                :
                <div className="">
                  <div className="topic confirm sources">
                    <div className="image">
                      <i className="fa fa-list fa-2" aria-hidden="true"></i>
                    </div>
                    <div className="words">
                      <h2>{Constants.SOURCE_NAME} feed</h2>
                      <p>
                        <strong>We found some content for you.</strong>
                      </p>
                      <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
                      <div className="input-area">
                        <form onSubmit={preventDefault()}>
                          <input autoFocus className="field-textbox"
                                 value={this.state.name}
                                 onChange={(e: any) => this.setState({ name: e.target.value })}/>
                          <Button onClick={() => this.addSource()}
                                  disabled={this.state.isLoading || !this.state.name}>Confirm</Button>
                          <Button className="change" onClick={() => this.setState({articles: {}})}>Change</Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          <this.limitReached/>
        </div>
    { this.state.isLoading &&
      <Loading.Loading size={Loading.SIZES.LARGE}/>
    }
    { !isEmpty(this.state.articles) &&
      <div className={`${this.context.store.getState().config.isMobile ? 'recent-articles-mobile' : 'recent-articles'}`}>
        <h4>Example Articles</h4>
      { this.state.articles.map((article, i) =>
        <Article key={i} article={article} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)
      }
      </div>
    }
    </div>: null)
  }

  protected limitReached = () => {
    const {maxTopicSourceCount, isUnlimited, maxLimitReached} = this.maxTopicLimit()
    return <div className="design-right-side">
          <div className="topic-source-right-side">
            {!isUnlimited ?
              <div className="div-progress-bar">
                <ProgressBar
                  min={0}
                  className={`progress-bar${maxLimitReached ? '-red' : ''}`}
                  max={maxTopicSourceCount}
                  value={this.state.accountTopicSourcesCount}
                  labelVisible={false}/>
                <div className="progress-bar-label">
                  <div>Topic Source used</div>
                  <div>{this.state.accountTopicSourcesCount}/{maxTopicSourceCount}</div>
                </div>
              </div> : null}
                {maxLimitReached &&
                  <UpgradeButton color={UPGRADE_BUTTON_COLOR.WHITE} showIcon={true}
                               source={GA.UpgradeSource.AddTopicSource} text="Upgrade"/>
               }
            </div>
          {maxLimitReached ?
            <div className="max-limit-description">
              You've reached your Topic Source limit, please upgrade your plan to add new Topic Source.
            </div> : null}
      </div>
      }

  public searchSource() {
    this.setState({isLoading: true}, () =>
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/topic/${this.state.selectedCommunity}/find`, HttpMethod.POST, {
          application: 'dashboard',
          topic: this.state.topic,
          identifier: this.state.selectedCommunity,
          limit: 5
        })
        .then((feed) => this.setState({
          articles: feed.items,
          found: true,
          isLoading: false,
          name: `News about '${this.state.topic}'`,
        }))
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.message : 'We could not locate any articles for this topic',
            articles: [],
            found: false,
            isLoading: false,
          })
        }))
  }

  public addSource() {
    const source = {
      community_id: this.context.user.activeCommunity.communityId,
      identifier: this.state.topic,
      name: this.state.name,
      type: SourceTypes.topic
    }
    this.props.propertiesChanged(source)
    this.setState({isLoading: true}, () =>
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/${this.context.user.activeCommunity.communityId}/topic/source`, HttpMethod.POST, {
          identifier: this.state.topic,
          name: this.state.name,
        })
        .then(() => postAddSource(this.context, 'google', source.identifier, this.props.push) )
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.message : 'You\'ve reached your Topic Source limit, please upgrade your plan to add new Topic Source',
            isLoading: false,
          })
        }))
  }

  private isValidTopic() {
    return this.state.topic.length > 3;
  }

  private filterTopicSourceTypeId = (data) => {
    return data.filter(item => item.sourcetype === Constants.SOURCE_NAME)
  }

  private maxTopicLimit = () => {
    const maxTopicSourceCount = BillingPlan.getCurrentPlanMaxTopicSource(this.state.plan)
    const isUnlimited = maxTopicSourceCount === BillingPlan.UNLIMITED_VALUE || maxTopicSourceCount === -1
    const maxLimitReached = !isUnlimited &&
      this.state.accountTopicSourcesCount >= maxTopicSourceCount
    return {
      maxTopicSourceCount,
      isUnlimited,
      maxLimitReached
    }
  }
}

export const ContentPoolAddTopicSource = GenericRedux.createConnect(
  ContentPoolAddTopicSourceComponent,
  Constants.REDUX_STORE_HOME,
)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(
      Constants.REDUX_STORE_HOME,
      {url: ''},
    ),
  }),
)
