export const REDUX_STORE_HOME = 'add_suggested_source'
export const SOURCE_NAME = 'SuggestedSource'
export const SUGGEST_SOURCES_PAGE = 'suggest-sources'
export const SUGGEST_ARTICLES_PAGE = 'suggest-articles'
export const SUGGEST_CONTENT_PAGE = 'suggest-content'
export const REX_COULD_NOT_FIND_MSG = "I'm sorry, but I couldn't find what you were looking for. Can you provide more context so I can better assist you?"
export const SHOW_MORE_ARTICLES_PROMPT = 'Show more articles'
export const ADD_ALL_ARTICLES_PROMPT = 'Add all articles to my content pool'

export interface Prompt {
  role: string,
  content: string,
  placeholder?: string,
}

export enum AIConfirmationType {
  ARTICLE_ADD = 'ARTICLE_ADD',
  ARTICLE_SUGGESTION_REQUEST = 'ARTICLE_SUGGESTION_REQUEST',
}

export enum CHATSOURCE {
  AI_ENGINE = 'AIEngine',
  RECOMMENDATION_ENGINE = 'RecommendationEngine',
  FULL_ARTICLES_LIST = 'FullArticles',
  RASA_RESPONSE = 'RasaResponse',
}

export enum PROMPT_ROLE {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant',
}

export const EXPLORE_MORE_PROMPT: Prompt = {
  role: "user",
  content: "Not satisfied with the given summary & topics. Want to explore more."
}
export const DEFAULT_PROMPTS: Prompt[] = [
  {
    role: "user",
    content: 'I need help deciding content for my next newsletter. Any suggestions?',
  },
  {
    role: "user",
    content: "Can you suggest some trending content for our upcoming newsletter?",
  },
  {
    role: "user",
    content: "I'm running low on articles for my newsletter. Any creative suggestions?"
  },
  {
    role: "system",
    content: "Enter a URL with content you like.",
    placeholder: "Please provide URL."
  }
]
